import {on} from 'martha'
import {component} from 'picoapp'

export default component(node => {
  const colors = ['#F3A712', '#F02F32', '#43459C', '#F060A2', '#E4572E']

  let id = null
  let delay = 750 / colors.length

  on(node, 'mouseenter', () => {
    step(0)

    function step(i) {
      node.style.backgroundColor = colors[i % colors.length]
      id = setTimeout(() => step(i + 1), delay)
    }
  })

  on(node, 'mouseleave', () => {
    id && clearTimeout(id)
    id = null
    node.style.backgroundColor = null
  })
})
