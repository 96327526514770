import './style.js'
import {picoapp} from 'picoapp'
import {on, size} from 'martha'
import clock from './components/clock'
import face from './components/face'
import cycle from './components/cycle'

const app = picoapp(
  {
    clock,
    face,
    cycle,
  },
  {
    ...size(),
  },
)

requestAnimationFrame(tick)
on(window, 'resize', resize)

app.mount()
resize()

function tick() {
  requestAnimationFrame(tick)
  app.emit('tick')
}

function resize() {
  app.emit('resize', size())
}
