import {add} from 'martha'
import {setup} from 'twind/shim'
import {tw, css, theme} from 'twind/css'

setup({
  mode: 'silent',
  theme: {
    extend: {
      screens: {
        '2xs': '375px',
        xs: '450px',
      },
    },
    fontFamily: {
      alpina: 'GT Alpina Condensed Light',
      hobeaux: 'Hobeaux',
    },
    fontSize: {
      13: '13px',
      15: '15px',
      24: '24px',
      28: '28px',
      34: '34px',
      36: '36px',
      40: '40px',
      50: '50px',
    },
    letterSpacing: {
      1: '0.01em',
    },
    colors: {
      green: '#124C2D',
      tan: '#F7F2E9',
      pink: '#F060A2',
      blue: '#43459C',
    },
  },
  preflight: {
    '@font-face': [
      {
        fontFamily: 'GT Alpina Condensed Light',
        fontWeight: 'normal',
        fontDisplay: 'swap',
        src: `url("/fonts/GT-Alpina-Condensed-Light.woff2") format("woff2"),
              url(/fonts/GT-Alpina-Condensed-Light.woff) format("woff")`,
      },
      {
        fontFamily: 'GT Alpina Condensed Light',
        fontWeight: 'normal',
        fontStyle: 'italic',
        fontDisplay: 'swap',
        src: `url("/fonts/GT-Alpina-Condensed-Light-Italic.woff2") format("woff2"),
              url(/fonts/GT-Alpina-Condensed-Light-Italic.woff) format("woff")`,
      },
      {
        fontFamily: 'Hobeaux',
        fontWeight: 'normal',
        fontDisplay: 'swap',
        src: `url("/fonts/Hobeaux-Regular.woff2") format("woff2"),
              url(/fonts/Hobeaux-Regular.woff) format("woff")`,
      },
    ],
  },
})

const globalStyles = css({
  ':global': {
    '::selection': {
      backgroundColor: theme('colors.blue'),
      color: theme('colors.tan'),
    },
  },
})

add(document.body, tw`${globalStyles}`)
