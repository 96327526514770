import {component} from 'picoapp'

export default component(node => {
  update(node)
  setInterval(() => update(node), 1000)
})

function update(el) {
  el.innerHTML = `${getTime()}`
}

function getTime() {
  return new Date().toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
  })
}
