import {component} from 'picoapp'
import choozy from 'choozy'
import {on, lerp, map, round, clamp, add, remove} from 'martha'
import {tw} from 'twind'
import {animation} from 'twind/css'

const blinky = animation('0.15s linear 1', {
  '0%': {
    transform: 'scaleY(1)',
  },
  '50%': {
    transform: 'scaleY(0)',
  },
  '100%': {
    transform: 'scaleY(1)',
  },
})

export default component((node, ctx) => {
  let refs = choozy(node)
  let ease = 0.15

  let lookDirection = node.dataset.look === 'right' ? 1 : -1
  let lookX = 0.75
  let lookY = -0.5

  let blinkRate = parseInt(node.dataset.blinkRate)

  let x = ctx.getState().ww / 2
  let y = ctx.getState().wh / 2
  let cx = 0
  let cy = 0

  poll(
    blinkRate,
    done => {
      const className = tw`${blinky}`
      Array.from(refs.eyes.children).forEach((el, i) => {
        let off = on(el, 'animationend', () => {
          off()
          remove(el, className)
          i === 1 && done()
        })
        add(el, className)
      })
    },
    false,
  )

  on(document, 'mousemove', ({clientX, clientY}) => {
    x = clientX
    y = clientY
  })

  ctx.on('tick', ({ww, wh}) => {
    let tx = clamp(map(x, 0, ww, -1, 1) + lookX * lookDirection, -1, 1)
    let ty = clamp(map(y, 0, wh, -1, 1) + lookY, -1, 1)

    cx = lerp(cx, tx, ease)
    cy = lerp(cy, ty, ease)

    const {face, eyes, nose, mouth} = {
      face: {
        x: round(cx * 0.6),
        y: round(cy * 0.6),
        r: round(cx * cy * 6),
      },
      eyes: {
        x: round(clamp(cx * 1.6, -1.6, 1.6)),
        y: round(clamp(cy * 0.8, -0.8, 0.8)),
      },
      nose: {
        r: round(clamp(-cx * 10, -10, 10)),
      },
      mouth: {
        r: round(clamp(cx * 10, -10, 10)),
      },
    }

    node.style.transform = `translate3d(${face.x}em, ${face.y}em, 0) rotate(${face.r}deg)`
    refs.eyes.style.transform = `translate3d(${eyes.x}em, ${eyes.y}em, 0)`
    refs.nose.style.transform = `rotate(${nose.r}deg)`
    refs.mouth.style.transform = `rotate(${mouth.r}deg)`
  })
})

function poll(delay, cb, first = true) {
  let timeoutId
  first ? cb(done) : done()
  function done() {
    timeoutId = setTimeout(() => cb(done), delay)
  }
  return () => clearTimeout(timeoutId)
}
